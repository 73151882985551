import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["base"];

  generatePalette() {
    const formData = new FormData();
    formData.append(
      "hex",
      this.baseTarget.innerHTML.trim()
    );

    ajax({
      url: `/brandings/${this.data.get('id')}/generate_palette`,
      type: "post",
      data: formData
    });
  }

  generateGradients() {
    const formData = new FormData();
    formData.append(
      "hex",
      this.baseTarget.innerHTML.trim()
    );

    ajax({
      url: `/brandings/${this.data.get('id')}/generate_gradients`,
      type: "post",
      data: formData
    });
  }
}
