import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.programModuleSelector = '.vat-line';
    this.contentPageSelector = '.content-page-item';
    this.programModuleCircleSelector = '.program-module-circle';
    this.programModuleBorderSelector = '.program-module-border';
    this.contentPagePillSelector = '.pill';

    this.pillUnstartedClass = 'bg-gray-200';
    this.pillInProgressClass = 'bg-yellow-500';
    this.pillCompletedClass = 'bg-green-500';

    this.programModuleUnstartedClass = 'gray';
    this.programModuleInProgressClass = 'yellow';
    this.programModuleCompletedClass = 'green';

    this.borderUnstartedClass = 'border-gray-400';
    this.borderInProgressClass = 'border-yellow-500';
    this.borderCompletedClass = 'border-green-500';

    this._setInitialStatusControl();
  }

  updateProgramModule(event) {
    this.currentRole = event.detail.currentRole;

    if (event.detail.currentRole !== 'learner') {
      return false;
    }

    this.contentPage = this.element.querySelector(event.detail.contentPage);
    this.programModule = this.contentPage.closest(this.programModuleSelector);
    this.contentPageItems = this.programModule.querySelectorAll(this.contentPageSelector);

    if (this.contentPageItems.length) {
      this.programModuleCircle = this.programModule.querySelector(this.programModuleCircleSelector);
      this.programModuleBorder = this.programModule.querySelector(this.programModuleBorderSelector);

      // Get status from program module's tasks
      this._getProgramModuleTasksStatus();

      // Reset program module status
      this._resetProgramModuleStatus();

      // Set new program module status
      this._updateProgramModuleStatus();

      // Reset program module status control
      this._setInitialStatusControl();
    }
  }

  _getProgramModuleTasksStatus() {
    for (let i = 0; i < this.contentPageItems.length; i++) {
      const contentPagePill = this.contentPageItems[i].querySelector(this.contentPagePillSelector);

      if (!contentPagePill) {
        continue;
      }

      if (contentPagePill.classList.contains(this.pillUnstartedClass)) {
        this.programModuleHasUnstartedTask = true;
      } else if (contentPagePill.classList.contains(this.pillInProgressClass)) {
        this.programModuleHasInProgressTask = true;
      } else {
        this.programModuleHasCompletedTask = true;
      }
    }
  }

  _resetProgramModuleStatus() {
    this.programModule.classList.remove(this.programModuleUnstartedClass, this.programModuleInProgressClass, this.programModuleCompletedClass);
    this.programModuleBorder.classList.remove(this.borderUnstartedClass, this.borderInProgressClass, this.borderCompletedClass);

    if (this._elementIsPresent(this.programModuleCircle)) {
      this.programModuleCircle.classList.remove(this.borderUnstartedClass, this.borderInProgressClass, this.borderCompletedClass);
    }
  }

  _updateProgramModuleStatus() {
    if (!this.programModuleHasInProgressTask && !this.programModuleHasCompletedTask) {
      // Set unstarted status
      this._setProgramModuleClasses(this.programModuleUnstartedClass, this.borderUnstartedClass);
    } else if (this.programModuleHasInProgressTask || (this.programModuleHasCompletedTask && this.programModuleHasUnstartedTask)) {
      // Set in progress status
      this._setProgramModuleClasses(this.programModuleInProgressClass, this.borderInProgressClass);
    } else if (!this.programModuleHasInProgressTask && !this.programModuleHasUnstartedTask) {
      // Set completed status
      this._setProgramModuleClasses(this.programModuleCompletedClass, this.borderCompletedClass);
      this.programModule.dataset.status = 'completed';
    }
  }

  _setProgramModuleClasses(programModuleClass, borderClass) {
    this.programModule.classList.add(programModuleClass);
    this.programModuleBorder.classList.add(borderClass);

    if (this._elementIsPresent(this.programModuleCircle)) {
      this.programModuleCircle.classList.add(borderClass);
    }
  }

  _elementIsPresent(element) {
    return typeof(element) != 'undefined' && element != null;
  }

  _setInitialStatusControl() {
    this.programModuleHasUnstartedTask = false;
    this.programModuleHasInProgressTask = false;
    this.programModuleHasCompletedTask = false;
  }

}
