import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = { url: String, form: String }

  connect() {
    this.boundInitializeForm = this.initializeForm.bind(this);
    this.boundHandleMention = this.handleMention.bind(this);
    // IE <= 10 => MSIE. IE 11 => Trident
    if (navigator.userAgent.indexOf("Trident") != -1) {
      setTimeout(function(){
        this.initializeButton();
      }.bind(this), 3000);
    } else {
      this.initializeButton();
    }
  }

  disconnect() {
    this.button.removeEventListener("click", this.boundInitializeForm);
  }

  initializeButton() {
    this.wrapper = this.buttonTarget;
    this.button = this.wrapper.firstElementChild;
    this.button.addEventListener("click", this.boundInitializeForm);
  }

  initializeForm() {
    this.handleMention();
  }

  handleMention = () => {
    let attachments = this.editor.composition.getAttachments()
    if (!attachments[0]) {
      this.fetchUser();
    } else {
      this.disconnectEvent();
    }
  }

  fetchUser() {
    fetch(`${this.urlValue}`)
      .then((response) => response.json())
      .then((mention) => this.setMention(mention))
      .catch((error) => this.logErr(error));
  }

  setMention(data){
    let attachment = new Trix.default.Attachment({
      content: data.encode.content,
      sgid: data.encode.sgid,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
    this.disconnectEvent();
  }

  disconnectEvent() {
    this.editor.element.removeEventListener("focus", this.boundHandleMention);
  }

  logErr(error){
    console.log(error);
  }

  get formEl() {
    return document.getElementById(`${this.formValue}`)
  }

  get inputEl() {
    return this.formEl.querySelector(".form-textarea")
  }

  get editor() {
    return this.inputEl.editor
  }
}
