import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["message"];

  static values = {
    selected: Number,
    parent: Number,
    text: String
  }

  connect() {
    this._onPageLoad();
  }

  _onPageLoad() {
    this._hideMessages();

    setTimeout(() => {
      let target = document.querySelector(`#js-main [id*="${this.selectedValue}"]`);

      if (target === null) {
        this._expandThreadAndHighlight();
      } else {
        this._highlight_message(target);
      }
      }, 500);
  }

  _expandThreadAndHighlight() {
    let thread = document.querySelector(`#js-replies-counter-text-${this.parentValue}`);

    thread.dispatchEvent(new Event('click'));

    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (document.querySelector(`#js-main [id*="${this.selectedValue}"]`) !== null) {
          resolve();
        }
      }, 500);
    });

    promise.then(() => {
      let target = document.querySelector(`#js-main [id*="${this.selectedValue}"]`);
      this._highlight_message(target);
    })
  }

  _highlight_message(target) {
    let targetId = target.id;

    if (targetId.includes('reply')) {
      target.firstElementChild.classList.add('border', 'border-green-300');

      let highlightTarget = target.firstElementChild.firstElementChild;

      highlightTarget.firstElementChild.innerHTML = this.textValue;
      highlightTarget.classList.remove('hidden');

      if (window.innerHeight < highlightTarget.getBoundingClientRect().bottom) {
        highlightTarget.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
      }
    }
  }

  _hideMessages() {
    this.messageTargets.forEach(target => target.classList.add('hidden'));
  }
}
