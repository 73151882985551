import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "form",
    "uploader",
    "uploading",
    "failed",
    "size",
    "format",
    "saved",
  ];

  connect() {
    // This controller is used both in NU and NgAdmin, therefore we need to take to classes for hiding into account
    if (this.uploadingTarget.classList.contains('hidden')) {
      this.classForHiding = 'hidden';
    } else {
      this.classForHiding = 'js-hidden';
    }

    this.uploaderTarget.addEventListener('change', (_event) => {
      Array.from(this.uploaderTarget.files).forEach(file => this.uploadFile(file))
    })
  }

  uploadFile(file) {
    this.displayUploading();

    // file_field direct_upload: true provides the data-direct-upload-url
    const url = this.uploaderTarget.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url)

    if (upload.file.size === 0) {
      this.displaySizeError();
      return false;
    }

    if (!upload.file.type.match('image')) {
      this.displayFormatError();
      return false;
    }

    upload.create((error, blob) => {
      if (error) {
        this.displayError();
      } else {
        this.updateUser(blob.signed_id);
      }
    })
  }

  updateUser(signed_blob_id) {
    const formData = new FormData();
    formData.append(
      "user[signed_blob_id]",
      signed_blob_id
    );

    ajax({
      url: this.formTarget.action,
      type: "patch",
      data: formData,
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this.displaySuccess();
      },
      error: () => {
        this.displayError();
      }
    });
  }

  displayUploading() {
    this.failedTarget.classList.add(this.classForHiding);
    this.formatTarget.classList.add(this.classForHiding);
    this.sizeTarget.classList.add(this.classForHiding);
    this.uploadingTarget.classList.remove(this.classForHiding);
  }

  displayError() {
    this.uploadingTarget.classList.add(this.classForHiding);
    this.formatTarget.classList.add(this.classForHiding);
    this.sizeTarget.classList.add(this.classForHiding);
    this.failedTarget.classList.remove(this.classForHiding);
  }

  displaySizeError() {
    this.uploadingTarget.classList.add(this.classForHiding);
    this.formatTarget.classList.add(this.classForHiding);
    this.sizeTarget.classList.remove(this.classForHiding);
  }

  displayFormatError() {
    this.uploadingTarget.classList.add(this.classForHiding);
    this.sizeTarget.classList.add(this.classForHiding);
    this.formatTarget.classList.remove(this.classForHiding);
  }

  displaySuccess() {
    this.uploadingTarget.classList.add(this.classForHiding);
    this.savedTarget.classList.remove(this.classForHiding);

    setTimeout(
      function () {
        this.savedTarget.classList.add(this.classForHiding);
      }.bind(this),
      8000
    );
  }
}
