import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static values = { message: String };

  sendPrompt(event) {
    this.target = event.currentTarget;

    this.prompt = this.target.dataset.prompt;

    if (this.prompt) {
      let path = this.target.dataset.path;

      const formData = new FormData();
      formData.append(
        'ai_assistants_new_form[rich_content]',
        this.prompt
      );

      ajax({
        url: path,
        type: "post",
        data: formData
      });
    }
  }
}
