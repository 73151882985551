import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trigger", "content"];

  toggle() {
    if (this.triggerTarget.dataset.hideSelf) {
      this.triggerTarget.classList.toggle("hidden");
    }

    this.contentTargets.forEach(element => {
      element.classList.toggle("hidden");
    });
  }
}
