import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.preconfigureOffsets();

    if (this.hasContentTarget) {
      this.scrollToTask();
    }

    document.addEventListener("promote:task-scroll", this.scrollToTask.bind(this));
  }

  preconfigureOffsets() {
    const stickyAccordions = document.querySelectorAll("[data-controller='components--sticky-sections']")
    const stickyAccordionsArray = Array.from(stickyAccordions);

    this.stickyHeaderHeight = (window.innerWidth < 640 ) ? 32 : 40;
    this.topIndex = stickyAccordionsArray.findIndex((element) => element.dataset["sectionId"] == 'task') + 1;

    this.topNavbarOffset = document.getElementById("js-header").offsetHeight;

    this.programBannersOffset = document.getElementById("js-program-banners").offsetHeight;
  }

  scrollToTask() {
    if (this.hasContentTarget) {
      const stickyTopOffset = this.topNavbarOffset + this.programBannersOffset + (this.topIndex * this.stickyHeaderHeight);
      const y = this.contentTarget.getBoundingClientRect().top + window.pageYOffset - stickyTopOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
}
