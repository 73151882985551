import consumer from "./consumer"

window.subscribeToAIChatChannel = (requestId) => {
  consumer.subscriptions.create(
    {
      channel: 'AIChatChannel',
      room: requestId
    },

    {
      connected() {
      },

      disconnected() {
      },

      received(data) {
        let formId = data.form_id;
        let text = data.text;

        let form = document.getElementById(formId);
        if(form) form.innerHTML = text;

        window.unsubscribeChannel('AIChatChannel', data.room);
      }
    }
  );
}
