import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['form', 'submit', 'spinner'];

  connect() {
    this.formTargets.forEach((form) => {
      form.addEventListener("submit", this.onSubmit.bind(this));
    });
  }

  disconnect() {
    this.formTargets.forEach((form) => {
      form.removeEventListener("submit", this.onSubmit);
    });
  }

  _disable_forms() {
    this.submitTargets.forEach((submit) => {
      submit.disabled = true;
    });
  }

  _showSpinner() {
    this.spinnerTarget.classList.remove('invisible');
  }

  _colorizeButton(element) {
    element.style.backgroundColor = 'var(--primary-800)';
  }

  onSubmit(event) {
    event.preventDefault();
    this._disable_forms();
    this._showSpinner();
    this._colorizeButton(event.target.getElementsByTagName('button')[0]);
    event.target.submit();
  }
}
