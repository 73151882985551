import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.debounceTimer = null;
    this.debounceInterval = 500;
    this.formTarget.addEventListener("submit", function (event) {
      event.preventDefault();
    });
  }

  load() {
    clearTimeout(this.debounceTimer);

    this.debounceTimer = setTimeout(
      this._loadForm.bind(this),
      this.debounceInterval
    );
  }

  paginate(event) {
    event.preventDefault();
    const url = event.currentTarget.pathname + event.currentTarget.search;
    this._load(url);
  }

  _loadForm() {
    const url = this._prepareUrl();
    this._entries();
    this._load(url);
  }

  _load(url) {
    const shouldUpdateUrl = this._shouldUpdateUrl();
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: url,
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: (response, statusText, xhr) => {
        if (shouldUpdateUrl) {
          window.history.replaceState({}, "", url);
        }
      },
      error: (response, statusText, xhr) => {
        // Redirect the window to the error page
        window.location = xhr.responseURL;
      },
    });
  }

  _prepareUrl() {
    const urlBase = this.formTarget.action;
    const queryString = this._prepareQueryString(urlBase);

    return urlBase + queryString;
  }

  _prepareQueryString(urlBase) {
    const entries = this._entries();
    const queryString = entries
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    if (urlBase.match(/\?/)) {
      return "&" + queryString;
    } else {
      return "?" + queryString;
    }
  }

  _entries() {
    const defaultEntries = this._defaultEntries();
    const formEntries = this._formEntries();
    const entries = Object.entries(formEntries).filter(
      ([key, value]) => defaultEntries[key] != value
    );

    return entries;
  }

  _formEntries() {
    const regex = /\[(.+?)\]/;
    const formData = new FormData(this.formTarget);

    const entries = Object.fromEntries([...formData.entries()]);

    // Delete keys that are in the form but not part of the form object, e.g. "utf8"
    Object.keys(entries).forEach(function (key) {
      if (!regex.test(key)) {
        delete entries[key];
      }
    });

    const cleanedEntries = Object.entries(entries).map(([key, value]) => [
      key.match(regex)[1],
      value,
    ]);

    return Object.fromEntries([...cleanedEntries]);
  }

  _defaultEntries() {
    return JSON.parse(this.data.get("defaults"));
  }

  _shouldUpdateUrl() {
    if (this.data.has("updateUrl")) {
      return this.data.get("updateUrl") === "true";
    } else {
      return true;
    }
  }
}
