import consumer from "./consumer"

window.subscribeToAIAssistantChannel = (threadId) => {
  consumer.subscriptions.create(
    {
      channel: 'AIAssistantChannel',
      room: threadId
    },

    {
      connected() {
      },

      disconnected() {
      },

      received(data) {
        const contextListEl = document.getElementById('thread-' + data.room);

        if (contextListEl) {
          const messageEl = contextListEl.querySelector('#js-message-ai-assistant-answer-placeholder-' + data.room);

          if (!messageEl) return;

          messageEl.querySelector('#js-ai-message-loading').outerHTML = data.text;
          messageEl.id = 'js-message-' + data.message_id;
        };

        window.unsubscribeChannel('AIAssistantChannel', data.room);
      }
    }
  );
}
