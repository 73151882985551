import { Controller } from 'stimulus';

import editTemplateComponent from '../promote_ui/edit_template_component.js';
import PromoteHelpers from '../ng_admin/deprecated_promote_helpers.js';

export default class extends Controller {
  static targets = ["editButton", "cancelEditButton", "content", "form", "message"];
  static values = { body: String }

  connect() {
    document.addEventListener("promote:focus-textarea", this.onFocusTextArea.bind(this));
  }

  edit(event) {
    const editButton = this.editButtonTarget.querySelector('button');
    if (editButton) editButton.disabled = true;

    editTemplateComponent(this.messageTarget, this.bodyValue, event).classList.remove("hidden");

    let textArea = this.formTarget.querySelector(".form-textarea");
    this._focusTextArea(textArea);
  }

  onFocusTextArea(event) {
    this._focusTextArea(event.detail.elm);
  }

  _focusTextArea(textareaEl) {
    let lastNode = textareaEl.childNodes[textareaEl.childNodes.length - 1];
    let lastPos = lastNode === undefined ? 0 : lastNode.textContent.length;

    textareaEl.focus();

    switch(textareaEl.nodeName) {
      case 'SPAN':
        window.getSelection().collapse(lastNode, lastPos);
        break;
      case 'TEXTAREA':
        textareaEl.setSelectionRange(lastPos, lastPos);
        break;
      case 'TRIX-EDITOR':
        lastPos = textareaEl.editor.getDocument().toString().length - 1;
        textareaEl.editor.setSelectedRange([lastPos, lastPos]);
        break;
    }
  }

  cancel() {
    const editButton = this.editButtonTarget.querySelector('button');
    if (editButton) editButton.disabled = false;

    this.formTarget.classList.add("hidden");

    this.contentTargets.forEach((contentTarget) =>
      contentTarget.classList.remove("hidden")
    );

    let textareaEl = this.formTarget.querySelector(".form-textarea");

    textareaEl.blur();

    // sends the id of the editor that is being cancelled
    PromoteHelpers.dispatchEvent({
      target: document,
      type: 'promote:cancel-changes',
      detail: {
        'id': textareaEl?.id
      }
    });
  }
}
