import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview", "label"];

  update(event) {
    this.previewTarget.style.backgroundColor = event.target.value;
    this.labelTarget.innerText = event.target.value;
  }
}
