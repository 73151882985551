import '../shared/airbrake.js';
import '../shared/trix_customisation.js';
import '../shared/actiontext.js';
import '../shared/confirm.js';
import '../../stylesheets/init.scss';
import '../shared/old_browser_check.js';
import '../third-party/tz.js';
import '@rails/actiontext';
import * as ActionCable from '@rails/actioncable'
// ActionCable.logger.enabled = true
require('javascripts/channels');

import RailsUjs from '@rails/ujs';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Turbolinks from 'turbolinks';
import { Trix } from 'trix';

let application;
const controllers = require.context("javascripts/stimulus_controllers", true, /_controller\.js$/);

if (module.hot) {
  module.hot.accept();

  if (module.hot.data) {
    application = module.hot.data.application;
  }
}

if (!application) {
  application = Application.start();

  Turbolinks.start();
  RailsUjs.start();

  // Enable browser's navigation buttons to work (back and forward)
  window.addEventListener("popstate", function (event) {
    Turbolinks.visit(location.toString());
  });
}

if (module.hot) {
  module.hot.dispose(data => {
    data.application = application;
  })
}

application.load(definitionsFromContext(controllers));
