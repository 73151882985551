import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = ["trigger", "content", "text"];

  static values = {
    show: String,
    hide: String,
    expanded: Boolean,
    loaded: Boolean,
    url: String
  }

  toggle() {
    if (this.expandedValue) {
      this.expandedValue = false;
      this.triggerTarget.querySelector('span').innerHTML = this.showValue;
      this._hideReplies();
    } else {
      this.expandedValue = true;
      this.triggerTarget.querySelector('span').innerHTML = this.hideValue;

      if (this.loadedValue) {
        this._showReplies();
      } else {
        this._loadReplies();
      }
    }
  }

  _loadReplies() {
    ajax({
      url: this.urlValue,
      type: "get",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this.loadedValue = true;
      }
    });
  }


  _hideReplies() {
    this.contentTargets.slice(2).forEach((element) =>  {
      element.classList.add('hidden')
    });
  }

  _showReplies() {
    this.contentTargets.forEach((element) =>  {
      element.classList.remove('hidden')
    });
  }
}
