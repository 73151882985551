import { ajax } from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['card', 'control', 'previousButton', 'nextButton'];

  initialize() {
    this.list = JSON.parse(this.controlTarget.dataset.learnersIds);
    this.listLength = this.list.length;

    this.programId = this.controlTarget.dataset.programId

    this.currentId = Number(this.cardTarget.dataset.learnerId);
    this.currentIndex = this.list.indexOf(this.currentId);

    this._handleNavigationButtons();
  }

  _handleNavigationButtons() {
    this._handlePreviousButton();
    this._handleNextButton();
  }

  _handlePreviousButton() {
    if (this.currentIndex === 0) {
      this.previousButtonTarget.disabled = true;
    } else {
      this.previousButtonTarget.disabled = false;
    }
  }

  _handleNextButton() {
    if (this.currentIndex === (this.listLength - 1)) {
      this.nextButtonTarget.disabled = true;
    } else {
      this.nextButtonTarget.disabled = false;
    }
  }

  previous() {
    this._hideContent();

    this.currentIndex = this.currentIndex - 1;
    this.currentId = this.list[this.currentIndex];

    this._showContent();
    this._handleNavigationButtons();
  }

  next(event) {
    this._hideContent();

    this.currentIndex = this.currentIndex + 1;
    this.currentId = this.list[this.currentIndex];

    this._showContent();
    this._handleNavigationButtons();
  }

  _hideContent() {
    document.getElementById(`learner-overview-${this.currentId}`).classList.toggle("hidden")
  }

  _showContent() {
    let element = document.getElementById(`learner-overview-${this.currentId}`)

    if (element) {
      element.classList.toggle("hidden");
    } else {
      this._loadData();
    }

    this._updateBrowserUrl();
  }

  _loadData() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(),
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  _updateBrowserUrl() {
    let base = `/programs/${this.controlTarget.dataset.programId}/learner_overviews/${this.currentId}`;
    let params = location.href.split('?')[1];

    if (params) {
      window.history.pushState({}, "", `${base}?${params}`);
    } else {
      window.history.pushState({}, "", base);
    }
  }

  _url() {
    return `/programs/${this.controlTarget.dataset.programId}/learner_overviews/${this.currentId}/navigation`;
  }
}

