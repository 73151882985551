import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = ['icon']
  disableUpdates = false;

  static values = {
    programId: Number
  }

  toggleCollapseState(event) {
    let url = `/programs/${this.programIdValue}/vat_cache?pm_updated=${event.target.closest('.js-program-module').dataset.pmId}`;

    this.sendUpdate(url);
  }

  collapseAll() {
    this.disableUpdates = true;
    this.iconTargets.forEach(target => {
      if (!target.classList.contains('collapsed')) {
        target.click();
      }
    });
    this.disableUpdates = false;

    let url = `/programs/${this.programIdValue}/vat_cache?pm_updated=collapsed`;
    this.sendUpdate(url);
  }

  expandAll() {
    this.disableUpdates = true;
    this.iconTargets.forEach(target => {
      if (target.classList.contains('collapsed')) {
        target.click();
      }
    });
    this.disableUpdates = false;

    let url = `/programs/${this.programIdValue}/vat_cache?pm_updated=expanded`;
    this.sendUpdate(url);

  }

  sendUpdate(url) {
    if (this.disableUpdates) { return; }

    ajax({
      url: url,
      type: "put",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    });
  }
}
