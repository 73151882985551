// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.
import consumer from "./consumer"

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

window.unsubscribeChannel = (channelName, room) => {
  const transformedName = '{"channel":"' + channelName + '","room":' + room + '}';
  let subscription = consumer.subscriptions.findAll(transformedName);

  subscription.forEach((sub) => {
    sub.unsubscribe();
  });

  // if there are no other subscriptions, close the connection
  if (consumer.subscriptions.subscriptions.length === 0) {
    consumer.disconnect()
  }
};
