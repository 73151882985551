import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['header', 'content']

  connect() {
    this.contentTarget.addEventListener("scroll", _.debounce(this.onScroll.bind(this), 20));
  }

  onScroll(event) {
    if (this.contentTarget.scrollTop > 0) {
      this.headerTarget.classList.add("shadow-vat");
    } else {
      this.headerTarget.classList.remove("shadow-vat");
    }
  }

  activateTask(event) {
    // Remove the class from the old element
    let previousActiveTaskEl = document
      .querySelector(".js-content-page-vat-active")

    if (previousActiveTaskEl) {
      previousActiveTaskEl.parentElement.classList.remove("bg-gray-200");
    }

    let currentActiveTaskEl = event.currentTarget;
    currentActiveTaskEl.parentElement.classList.add("bg-gray-200");
  }
}
