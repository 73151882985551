import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    // IE <= 10 => MSIE. IE 11 => Trident
    if (navigator.userAgent.indexOf("Trident") != -1) {
      setTimeout(function(){
        this.setupToolbarButton()
      }.bind(this), 3000);
    } else {
      this.setupToolbarButton()
    }
  }

  setupToolbarButton = () => {
    this.initializeEditor();
    this.initializeButton();
  }

  initializeEditor = () => {
    this.trixElement = this.element.querySelector('trix-editor')
  }

  initializeButton = () => {
    this.trixElement.addEventListener("trix-initialize", this.disableButton.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("trix-initialize", this.disableButton.bind(this))
  }

  disableButton() {
    if (this.buttonTarget) this.buttonTarget.disabled = true
  }
}
