import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

import PromoteHelpers from '../ng_admin/deprecated_promote_helpers.js';

export default class extends Controller {
  static targets = ["textarea", "replyField", "messageField", "form", "assistantField", "assistantButtons", "feedField", "feedFieldButtons", "inlineMessage"];
  static values = { replyId: String }

  reset(event) {
    if (this.textareaTarget.innerHTML === "") {
      this._resetField();
      return;
    }

    let target = event.target;

    if (target.nodeName === 'SPAN') {
      target = target.closest('button');
    }

    if (Rails.confirm(null, target)) {
      this.textareaTarget.innerHTML = "";
      this._resetField();

      // sends the id of the editor that is being cancelled
      PromoteHelpers.dispatchEvent({
        target: document,
        type: 'promote:cancel-changes',
        detail: {
          'id': this.textareaTarget.id
        }
      });
    } else {
      event.preventDefault();
    }
  }

  _resetField() {
    if (this.hasFormTarget) {
      this.formTarget.classList.add('hidden');
    }

    if (this.hasReplyFieldTarget) {
      this.replyFieldTarget.classList.remove('hidden');
    }

    if (this.hasFeedFieldTarget) {
      this.feedFieldTarget.classList.remove('hidden');
      this.feedFieldButtonsTarget.classList.remove('hidden');
    }

    if (this.hasAssistantFieldTarget) {
      this.assistantFieldTarget.classList.remove('hidden');
      this.assistantButtonsTarget.classList.remove('hidden');
    }

    if (this.hasMessageFieldTarget) {
      this.messageFieldTarget.classList.remove('hidden');

      if (this.hasInlineMessageTarget) {
        this.inlineMessageTargets.forEach(inlineMsg => {
          inlineMsg.classList.remove('invisible');
        });
      }
    }
  }

  openMessageForm() {
    this.messageFieldTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');

    // Focus when we open the message form
    this.formTarget.querySelector('.form-textarea').innerHTML = '';
    this.formTarget.querySelector('.form-textarea').focus();
  }

  openFeedForm() {
    this.feedFieldTarget.classList.add('hidden');
    this.feedFieldButtonsTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');

    // Focus when we open the reply form
    this.formTarget.querySelector('.form-textarea').focus();
  }

  openAssistantForm() {
    this.assistantFieldTarget.classList.add('hidden');
    this.assistantButtonsTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');

    this.formTarget.querySelector('.input-form').classList.remove('hidden');
    this.formTarget.querySelector('.form-textarea').innerHTML = '';
    this.formTarget.querySelector('.form-textarea').focus();
  }
}
