import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [ 'passcodeField', 'inputField' ]

	handleInput(event) {
	  const inputField = event.target;
	  const index = inputField.dataset.index;

	  const character = inputField.value;

	  const passcode = this.inputFieldTargets.map(input => input.value).join("");

	  this.passcodeFieldTarget.value = passcode;

	  const nextField = this.inputFieldTargets[+index + 1];
	  if (character && nextField) nextField.focus();
	}

	handleBackspace(event) {
		if (event.key !== "Backspace") return

    const inputField = event.target;
    const index = inputField.dataset.index;

    const previousField = this.inputFieldTargets[+index - 1];
    if (previousField) previousField.focus()
	}
}
