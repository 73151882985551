import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.lgBreakpoint = 992; // lg breakpoint in px
  }

  toggle(event) {
    event.preventDefault();
    var body = document.getElementsByTagName("body")[0];

    if (body.classList.contains("display-menu")) {
      body.classList.remove("display-menu");
    } else {
      body.classList.add("display-menu");
    }
  }

  responsiveHide(event) {
    var body = document.getElementsByTagName("body")[0];

    if (
      body.classList.contains("display-menu") &&
      window.innerWidth >= this.lgBreakpoint
    ) {
      body.classList.remove("display-menu");
    }
  }
}
