import { Controller } from "stimulus";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['input', 'item', 'fallback'];

  initialize() {
    this.filterOnInput = debounce(this.filterOnInput, 300).bind(this)
  }

  filterOnInput(event) {
    let target = event.target;
    let filterValue = target.value.toUpperCase();
    let anyMatch = false;

    this.itemTargets.forEach((item, index) => {
      let itemValue = item.innerText.toUpperCase();
      let matchItem = itemValue.includes(filterValue);

      if(matchItem) anyMatch = true;

      // Hide item if does not match with filter value
      item.classList.toggle("hidden", !matchItem);
    })

    // Show fallback message if anyMatch is true or hide when is false
    this._handle_fallback(anyMatch);
  }

  _handle_fallback(anyMatch) {
    this.fallbackTarget.classList.toggle("hidden", anyMatch)
  }
}
