import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "saml",
    "moreOptions",
    "backToSSO",
    "password",
    "magicLink",
    "activateAccount",
  ]

  static values = { defaultStrategy: String }

  connect(event) {
    let currentAuthMethod = window.sessionStorage.getItem(this._storageKey());

    if (currentAuthMethod == this._authMethods().saml) {
      this._showSAML();
    } else if (currentAuthMethod == this._authMethods().password) {
      this._showPassword();
    } else if (currentAuthMethod == this._authMethods().magicLink) {
      this._showMagicLink();
    }
  }

  displayMoreOptions(event) {
    event.preventDefault();
    this._hideMoreOptions();

    if (this.defaultStrategyValue == 'password') {
      this._showPassword();
    } else if (this.defaultStrategyValue == 'magic_link') {
      this._showMagicLink();
    }
  }

  showSAMLHandler(event) {
    event.preventDefault();
    this._showSAML();
  }

  showActivateAccountHandler(event) {
    event.preventDefault();
    this._showActivateAccount();
  }

  showPasswordHandler(event) {
    event.preventDefault();
    this._showPassword();
  }

  showMagicLinkHandler(event) {
    event.preventDefault();
    this._showMagicLink();
  }

  _showSAML() {
    if (!this.hasSamlTarget) return;

    this.samlTargets.forEach((samlEl) => samlEl.classList.remove('js-hidden'));
    this._hideBackToSSO();
    this._hideSignInForms();
    this._showMoreOptions();

    window.sessionStorage.setItem(this._storageKey(), this._authMethods().saml);
  }

  _showActivateAccount() {
    if (!this.hasActivateAccountTarget) return;

    this.samlTargets.forEach((samlEl) => samlEl.classList.add('js-hidden'));
    this._hideMoreOptions();
    this._hideSignInForms();
    this.activateAccountTarget.classList.remove('hidden');
    window.sessionStorage.setItem(this._storageKey(), this._authMethods().password);
  }

  _showPassword() {
    if (!this.hasPasswordTarget) return;

    this.samlTargets.forEach((samlEl) => samlEl.classList.add('js-hidden'));
    this._showBackToSSO();
    this._hideSignInForms();
    this._hideMoreOptions();
    this.passwordTarget.classList.remove('hidden');
    window.sessionStorage.setItem(this._storageKey(), this._authMethods().password);
  }

  _showMagicLink() {
    if (!this.hasMagicLinkTarget) return;

    this.samlTargets.forEach((samlEl) => samlEl.classList.add('js-hidden'));
    this._showBackToSSO();
    this._hideMoreOptions();
    this._hideSignInForms();
    this.magicLinkTarget.classList.remove('hidden');
    window.sessionStorage.setItem(this._storageKey(), this._authMethods().magicLink);
  }

  _hideMoreOptions() {
    if (this.hasMoreOptionsTarget) this.moreOptionsTarget.classList.add('hidden');
  }

  _showMoreOptions() {
    if (this.hasMoreOptionsTarget) this.moreOptionsTarget.classList.remove('hidden');
  }

  _hideSignInForms() {
    if (this.hasPasswordTarget) this.passwordTarget.classList.add('hidden');
    if (this.hasMagicLinkTarget) this.magicLinkTarget.classList.add('hidden');
    if (this.hasActivateAccountTarget) this.activateAccountTarget.classList.add('hidden');
  }

  _showBackToSSO() {
    if (this.hasBackToSSOTarget) {
      this.backToSSOTarget.classList.remove('invisible');
    }
  }

  _hideBackToSSO() {
    if (this.hasBackToSSOTarget) {
      this.backToSSOTarget.classList.add('invisible');
    }
  }

  _storageKey() {
    return 'promote.login.authMethod';
  }

  _authMethods() {
    return {
      saml: 'saml',
      password: 'password',
      magicLink: 'magicLink'
    };
  }
}
