import { ajax } from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["form"];

  connect() {
    if (this._scormRedirectRequest()) {
      this._initializeScormTaskData();

      if (this.incompleteTask) this.processScormCallback()
    }
  }

  _scormRedirectRequest() {
    return window.location.href.includes('scorm_cloud_redirect=true')
  }

  _initializeScormTaskData() {
    this.taskPath = location.href.split('?')[0];
    this.url = `${this.taskPath}?update_vat=true`;
    this.waitInterval = this.formTarget.dataset.scormTaskScormInterval;
    this.incompleteTask = this.formTarget.dataset.scormTaskTaskStatus !== 'completed';
  }

  processScormCallback() {
    setTimeout(() => {
      this.loadTaskPage()
    }, this.waitInterval)
  }

  loadTaskPage() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this.url,
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: (response, statusText, xhr) => {
        window.history.pushState({}, "", this.taskPath);
      },
      error: (response, statusText, xhr) => {
        window.location = xhr.responseURL;
      },
    })
  }
}
