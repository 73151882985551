import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sidebarItem", "mainItem", "asideItem", "activeVatItem"];

  selectSidebar() {
    this._select(this.sidebar, this.sidebarItemTarget);
    this._deselectDefault(this.sidebarItemTarget);

    this._deselect(this.main, this.mainItemTarget);
    if(this.hasAsideItemTarget) {
      this._deselect(this.aside, this.asideItemTarget);
    }

    if (this.hasActiveVatItemTarget) {
      this._scrollToActiveItem();
    }
  }

  selectMain() {
    if (!this.hasMainItemTarget) { return; }

    this._select(this.main, this.mainItemTarget);
    this._selectDefault(this.sidebarItemTarget);

    this._deselect(this.sidebar, this.sidebarItemTarget);
    if(this.hasAsideItemTarget) {
      this._deselect(this.aside, this.asideItemTarget);
    }
  }

  selectAside() {
    this._select(this.aside, this.asideItemTarget);
    this._deselectDefault(this.sidebarItemTarget);

    this._deselect(this.sidebar, this.sidebarItemTarget);
    this._deselect(this.main, this.mainItemTarget);
  }

  _select(element, item) {
    element.classList.add("toolbar-active");
    item.classList.add("toolbar-active-item");
  }

  _deselect(element, item) {
    element.classList.remove("toolbar-active");
    item.classList.remove("toolbar-active-item");
  }

  _selectDefault(item) {
    item.classList.add("toolbar-default-item");
  }

  _deselectDefault(item) {
    item.classList.remove("toolbar-default-item");
  }

  get sidebar() {
    return document.querySelector("#js-sidebar");
  }

  get main() {
    return document.querySelector("#js-main");
  }

  get aside() {
    return document.querySelector("#js-aside");
  }

  _scrollToActiveItem() {
    const activeItem = document.querySelector('.js-content-page-vat-active');

    if(activeItem) {
      activeItem.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }
}
