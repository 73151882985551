import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "textarea"];

  connect() {
    if (!this.hasTextareaTarget) return;

    this.fieldWrapper = this.element.querySelector(".drag-wrapper");

    // Drag in events
    this.textareaTarget.addEventListener('dragover', this.addDragAndDropStyle.bind(this));
    this.textareaTarget.addEventListener('dragenter', this.addDragAndDropStyle.bind(this));

    // Drag out events
    this.textareaTarget.addEventListener('dragleave', this.removeDragAndDropStyle.bind(this));
    this.textareaTarget.addEventListener('dragend', this.removeDragAndDropStyle.bind(this));
    this.textareaTarget.addEventListener('drop', this.removeDragAndDropStyle.bind(this));
  }

  disconnect() {
    if (!this.hasTextareaTarget) return;

    this.textareaTarget.removeEventListener('dragover', this.addDragAndDropStyle.bind(this));
    this.textareaTarget.removeEventListener('dragenter', this.addDragAndDropStyle.bind(this));
    this.textareaTarget.removeEventListener('dragleave', this.removeDragAndDropStyle.bind(this));
    this.textareaTarget.removeEventListener('dragend', this.removeDragAndDropStyle.bind(this));
    this.textareaTarget.removeEventListener('drop', this.removeDragAndDropStyle.bind(this));
  }

  addDragAndDropStyle() {
    this.fieldTarget.classList.add('drag-over');

    if (this.fieldWrapper) {
      this.fieldWrapper.classList.remove('border', 'border-gray-400', 'focus-within:border-primary-700', 'focus-within:shadow-brand', 'error:border-red-500');
    }
  }

  removeDragAndDropStyle() {
    this.fieldTarget.classList.remove('drag-over');

    if (this.fieldWrapper) {
      this.fieldWrapper.classList.add('border', 'border-gray-400', 'focus-within:border-primary-700', 'focus-within:shadow-brand', 'error:border-red-500');
    }
  }
}
