import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "tab"];

  select(event) {
    if (event.currentTarget.classList.contains("disabled")) {
      return false;
    }


    let target = event.target;

    if (target.nodeName !== 'LI') {
      target = target.closest('li');
    }

    this._updateFormFieldValues(target);
    this._removeBackgrounds();
    this._setBackground(target);
  }

  _updateFormFieldValues(target) {
    let formName = this.inputTarget.name.split('[')[0];
    const formFieldName = target.dataset.filterName;
    const formFieldValue = target.dataset.filterValue;

    let formFields = document.querySelectorAll(`[name="${formName}[${formFieldName}]"]`);

    formFields.forEach(field => {
      field.value = formFieldValue;
    })
  }

  _removeBackgrounds() {
    this.tabTargets.forEach(tab => {
      tab.classList.remove('active');
    })
  }

  _setBackground(target) {
    target.classList.add('active');
  }
}
