import { Controller } from 'stimulus';
import { Chart, BarController, Tooltip, LinearScale, BarElement, CategoryScale, PointElement, Legend, Colors } from "chart.js";

Chart.register(BarController, Tooltip, LinearScale, BarElement, CategoryScale, PointElement, Legend, Colors);

export default class extends Controller {
  static targets = ["chart"];
  static values = {
    chartData: Array
  };

  connect() {
    this.drawChart();
  }

  dataset(labels) {
    return this.chartDataValue.map((d) => (
      {
        label: d.collection_title_with_count,
        data: labels.map((name) => (
          +d.data[name] * 100
        ))
      }
    ));
  }

  drawChart() {
    let labels = Object.keys(this.chartDataValue[0].data)

    let config = {
      type: 'bar',
      options: {
        scales: {
          y: {
            suggestedMax: 100,
            ticks: {
              callback: (value, index, values) => {
                return `${value}%`
              }
            }
          }
        },
        plugins: {
          colors: {
            enabled: true
          },
          legend: {
            display: true,
            align: 'start',
            position: 'top',
            labels: {
              usePointStyle: true
            },
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                if (context.parsed.y == null) {
                  return "0%";
                } else {
                  return context.parsed.y + "%";
                }
              }
            }
          }
        }
      },

      data: {
        labels: labels,
        datasets: this.dataset(labels)
      }
    };

    let chart = new Chart(this.chartTarget, config);
  }
}
