import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['tab', 'content'];

  openTab(event) {
    event.preventDefault();

    let target = event.target;

    if (target.nodeName === 'A') {
      target = target.parentElement;
    }

    this.tabTargets.forEach((tab) => {
      if (tab.classList.contains('active')) {
        tab.classList.remove('active');
      }
    });

    target.classList.add('active');

    let linkId = target.dataset.linkId;

    this.contentTargets.forEach((content) => {
      if (content.id === linkId) {
        content.classList.remove('hidden');
      } else {
        content.classList.add('hidden')
      }
    });
  }
}
