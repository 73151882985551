import { Controller } from "stimulus";
import Tribute from "tributejs";

export default class extends Controller {
  static targets = ["field"];
  static values = { url: String, noMatch: String }

  connect() {
    // IE <= 10 => MSIE. IE 11 => Trident
    if (navigator.userAgent.indexOf("Trident") != -1) {
      setTimeout(function(){
        this.prepareMentions();
      }.bind(this), 3000);
    } else {
      this.prepareMentions();
    }
  }

  prepareMentions() {
    this.fieldTarget.addEventListener("trix-initialize", this.initializeTribute);
  }

  disconnect() {
    this.fieldTarget.removeEventListener("trix-initialize", this.initializeTribute);
    if (this.tribute) this.tribute.detach(this.fieldTarget)
  }

  initializeTribute = (event) => {
    this.editor = event.target.editor;
    this.toolbar = this.editor.element.toolbarElement;
    this.tribute = new Tribute({
      allowSpaces: true,
      exactMatch: true,
      lookup: "name",
      values: this.fetchUsers.bind(this),
      noMatchTemplate: this.fetchNoMatchTranslation.bind(this),
    });

    this.tribute.attach(this.fieldTarget);
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);

    const mentionButton = this.toolbar.querySelector('trix-button--icon-mention') ||
      this.fieldTarget.parentNode.getElementsByClassName('trix-button--icon-mention')[0];

    if (mentionButton) {
      mentionButton.addEventListener("mouseup", this.onMention.bind(this));
    }
  }

  onMention(event) {
    event.preventDefault();

    this.tribute.showMenuForCollection(this.fieldTarget);
  }

  fetchUsers(text, callback) {
    const list = this.urlValue.split('?');
    const url = list.shift();
    const params = list.length > 0 ? '&' + list.join('&') : '';
    fetch(`${url}?query=${text}${params}`)
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch((error) => callback([]));
  }

  fetchNoMatchTranslation(text, callback) {
    return (`<li>${this.noMatchValue}</li>`);
  }

  replaced(e) {
    let mention = e.detail.item.original;
    let attachment = new Trix.default.Attachment({
      content: mention.content,
      sgid: mention.sgid,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(html, startPos, endPos) {
    let range = this.editor.getSelectedRange();
    let position = range[0];
    let length = endPos - startPos;

    this.editor.setSelectedRange([position - length, position]);
    this.editor.deleteInDirection("backward");
  }
}
