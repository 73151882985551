import { Controller } from "stimulus";
import { EmojiButton } from '@joeattardi/emoji-button';
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    // IE <= 10 => MSIE. IE 11 => Trident
    if (navigator.userAgent.indexOf("Trident") != -1) {
      setTimeout(function(){
        this.initializeEmojiPicker();
      }.bind(this), 3000);
    } else {
      this.initializeEmojiPicker();
    }
  }

  disconnect() {
    this.fieldTarget.removeEventListener('trix-initialize', this.initializeTrigger);
    if (this.picker) this.picker.destroyPicker()
  }

  initializeEmojiPicker() {
    this.trigger = this.fieldTarget.parentNode.getElementsByClassName('trix-button--icon-emoji')[0];

    if (this.trigger) {
      this.initializeTrigger();
    } else {
      this.fieldTarget.addEventListener('trix-initialize', this.initializeTrigger);
    }
  }

  initializeTrigger = () => {
    this.editor = this.fieldTarget.editor;
    this.toolbar = this.editor.element.toolbarElement;
    // emojiButton can not be passed as a static target because the element is
    // not present at the time that stimulus is loaded
    // we have the same issue with the mentions button
    this.trigger = this.toolbar.querySelector('trix-button--icon-emoji') ||
      this.fieldTarget.parentNode.getElementsByClassName('trix-button--icon-emoji')[0];

    if (this.trigger) {
      this.trigger.classList.remove('hidden');
      this.trigger.addEventListener('click', this.buildPicker.bind(this));
    }
  }

  buildPicker() {
    if (!this.picker) {
      this.picker = new EmojiButton({
        emojiSize: '1.5em'
      });

      this.picker.on('emoji', selection => {
        this.editor.insertString(selection.emoji);
      });
    }

    this.picker.togglePicker(this.trigger)
  }
}
