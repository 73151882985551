import { Controller } from "stimulus";
import { Chart, ArcElement, DoughnutController, Tooltip, LinearScale } from "chart.js";

Chart.register(ArcElement, DoughnutController, Tooltip, LinearScale);

export default class extends Controller {
  static targets = ["chart", "gray", "green"];

  static values = {
    remaining: Number,
    passed: Number
  }

  connect() {
    if (this.chartTarget.classList.contains("chartjs-render-monitor")) {
      const chartId = this.chartTarget.dataset.chartId;
      const chart = Chart.instances[chartId];
      chart.data.datasets = this._chartConfig().data.datasets;
      chart.update();
    } else {
      this.drawChart();
      this.chartTarget.classList.add("chartjs-render-monitor");
    }
  }

  drawChart() {
    const chart = new Chart(this.chartTarget, this._chartConfig());
    this.chartTarget.dataset.chartId = chart.id;
  }

  _chartConfig() {
    return {
      type: "doughnut",
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
          }
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        cutout: '85%',
        responsive: true,
        aspectRatio: 1
      },
      data: {
        datasets: [
          {
            data: [this.passedValue, this.remainingValue],
            backgroundColor: [getComputedStyle(this.greenTarget).backgroundColor, getComputedStyle(this.grayTarget).backgroundColor],
          },
        ],
      },
    };
  }
}
