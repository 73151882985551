import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['filter', 'module']

  filterOnClick(event) {
    let target = event.target;

    if (target.classList.contains('active')) {
      return;
    }

    this.switchActiveFilter(target);

    if (target.dataset.filterValue === 'remaining') {
      this.displayRemaining();
    } else {
      this.displayAll();
    }
  }

  switchActiveFilter(target) {
    this.filterTargets.forEach(filter => filter.classList.remove('active'));

    target.classList.add('active');
  }

  displayRemaining() {
    if (this.hasModuleTarget) {
      this.moduleTargets.forEach(module => {
        if (module.dataset.status === 'completed') {
          module.classList.add('hidden');
        }
      })

      for (let i = this.moduleTargets.length - 1; i >= 0 ; i--) {
        if (!this.moduleTargets[i].classList.contains('hidden')) {
          this.moduleTargets[i].classList.add('hide-line');
          break;
        }
      }
    }
  }

  displayAll() {
    if (this.hasModuleTarget) {
      this.moduleTargets.forEach(module => {
        module.classList.remove('hidden');
        module.classList.remove('hide-line');
      })
    }
  }
}
