import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = ["field"];

  static outlets = ['flash-messages'];

  connect() {
    this.trigger = this.fieldTarget.parentNode.getElementsByClassName('trix-button--icon-ai')[0];

    if (this.trigger) {
      this.initializeTrigger();
    } else {
      this.fieldTarget.addEventListener("trix-initialize", this.initializeTrigger);
    }
  }

  disconnect() {
    this.fieldTarget.removeEventListener('trix-initialize', this.initializeTrigger);
    if (this.trigger) {
      this.trigger.removeEventListener('click', this._displayMenu.bind(this));
      this.trigger.removeEventListener('blur', this._hideMenu.bind(this));
    }
  }

  initializeTrigger = (event) => {
    this._initializeAIPicker();
    this._setMessageIdForChatMessages();
  }

  _initializeAIPicker() {
    this.trigger = this.fieldTarget.editor.element.toolbarElement.querySelector('trix-button--icon-ai') ||
      this.fieldTarget.parentNode.getElementsByClassName('trix-button--icon-ai')[0];

    if (this.trigger) {
      this.trigger.classList.remove('hidden');
      this.trigger.addEventListener('click', this._displayMenu.bind(this), true);
      this.trigger.addEventListener('blur', this._hideMenu.bind(this));
    }
  }

  _displayMenu(event) {
    if (event.target !== this.trigger) {
      return;
    }

    if (this.trigger.firstElementChild) {
      this._showMenu();
    } else {
      this._createMenu();
    }
  }

  _hideMenu() {
    this.trigger.firstElementChild.classList.add('hidden');
  }

  _showMenu() {
    let menu = this.trigger.firstElementChild;
    menu.classList.remove('hidden');
  }

  _createMenu() {
    let idSelector = this.fieldTarget.dataset.richTextAiMenuId;
    let template = document.getElementById(idSelector);
    let clonedTemplate = template.content.firstElementChild.cloneNode(true);

    let split_id = this.fieldTarget.id.split('_');
    let newtemplateId = split_id.slice(2, split_id.length).join('-');

    clonedTemplate.id = `${template.id}-${newtemplateId}`;

    clonedTemplate.querySelectorAll('.js-ai-response').forEach(elem => {
      elem.addEventListener('click', this._getResponse.bind(this));
    })
    this.trigger.append(clonedTemplate);
  }

  _getResponse(event) {
    this._addWaitInformation(event.target);

    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    let path = this.fieldTarget.dataset.richTextAiPath;

    ajax({
      url: path,
      type: "post",
      dataType: "script",
      data: this._formData(event.target),
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      error: (response) => {
        this._displayErrorMessage(response.error_description);
        this._removeWaitPlaceholder();
        this._enableAIMenuButton();
      },
      success: () => {
        this._enableAIMenuButton();
      }
    })
  }

  _formData(target) {
    const formData = new FormData();
    formData.append(
      "response_type", target.dataset.aiResponseType
    );

    formData.append(
      "form_id", this.fieldTarget.id
    );

    const programUuid = document.querySelector('meta[name="program"]');
    if (programUuid) {
      formData.append(
        "program_uuid", programUuid.content
      );
    }

    if (this.fieldTarget.dataset.aiParentId) {
      formData.append(
        "parent_id", this.fieldTarget.dataset.aiParentId
      );
    }

    if (this.fieldTarget.dataset.aiChatMessageId) {
      formData.append(
        "chat_message_id", this.fieldTarget.dataset.aiChatMessageId
      );
    }

    let taskIdInput = document.querySelector('[name="messages_new_form[task_id]"]')

    if (taskIdInput) {
      formData.append(
        "task_id", taskIdInput.value
      );
    }

    return formData;
  }

  _addWaitInformation(target) {
    this._hideMenu();
    this._disableAIMenuButton();
    this._addWaitPlaceholder(target);
  }

  _addWaitPlaceholder(target) {
    let waitMessage = target.parentNode.dataset.aiWaitMessage;
    let placeholder = document.createElement('pre');
    placeholder.innerHTML = waitMessage;

    this.fieldTarget.innerHTML = '';
    this.fieldTarget.append(placeholder);
  }

  _removeWaitPlaceholder() {
    this.fieldTarget.innerHTML = '';
  }

  _disableAIMenuButton() {
    this.trigger.disabled = true;
  }

  _enableAIMenuButton() {
    this.trigger.disabled = false;
  }

  _displayErrorMessage(message) {
    if (message === undefined) {
      this.flashMessagesOutlet.displayStandardErrorMessage();
    } else {
      this.flashMessagesOutlet.displayErrorMessage(message);
    }
  }

  _setMessageIdForChatMessages() {
    let listMessages = this.fieldTarget.closest('#js-list-messages');

    if (!listMessages) {
      listMessages = this.fieldTarget.closest('#team-list-discussion');
    }

    if (!listMessages) {
     return;
    }

    let firstMessageInTread = listMessages.querySelector('[id^=js-message-]');

    if (!firstMessageInTread) {
      return;
    }

    if (firstMessageInTread.classList.contains('js-chat-message')) {
      this.fieldTarget.dataset.aiChatMessageId = firstMessageInTread.id.split('-').slice(-1)[0];
    }
  }
}
