import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this._processFullscreenRequest();
    window.addEventListener('message', this.onMessage.bind(this));
  }

  disconnect() {
    window.removeEventListener('message', this.onMessage.bind(this));
  }

  _processFullscreenRequest() {
    const iframe = this.element.querySelector("iframe");

    if (iframe) this._postFullscreenMessage(iframe);
  }

  _postFullscreenMessage(iframe) {
    iframe.allowFullscreen = true;
    iframe.webkitallowfullscreen = true;
    iframe.mozallowfullscreen = true;

    iframe.contentWindow.postMessage('request-fullscreen', '*');
  }

  onMessage(event) {
    if (event.data !== 'request-fullscreen') return;

    this.showFullscreenIcon(document);
  }

  showFullscreenIcon(iframeDocument) {
    const fullscreenIcon = iframeDocument.querySelector('button[data-fullscreen-button]'),
          vimeoPlayer = iframeDocument.querySelector(".player.no-fullscreen-support");

    if (vimeoPlayer) vimeoPlayer.classList.remove('no-fullscreen-support');
    if (fullscreenIcon) fullscreenIcon.style.display = 'block';
  }
}
