import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["content"];

  connect() {
    if (this.hasContentTarget) {
      this.scrollToContent();
    }
  }

  scrollToContent() {
    this.topNavbarOffset = document.getElementById("js-header").offsetHeight;
    this.programBannersOffset = document.getElementById("js-program-banners").offsetHeight;

    const stickyTopOffset = this.topNavbarOffset + this.programBannersOffset;
    const y = this.contentTarget.getBoundingClientRect().top + window.pageYOffset - stickyTopOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }
}
