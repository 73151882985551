import PromoteHelpers from '../ng_admin/deprecated_promote_helpers.js';

export default function editTemplateComponent(messageEl, templateBody, event) {
  const messageId = messageEl.dataset.templateMessageId;
  const componentId = `edit-message-${messageId}`;
  const aiEnabled = messageEl.dataset.templateAiEnabled;
  const enableAi = enableAiForMessage(aiEnabled, messageEl);

  // If component was already added to the DOM, reuse it
  let componentEl = document.getElementById(componentId);
  if (componentEl) {
    const textAreaEl = componentEl.querySelector('[data-template-target="textarea"]');
    replaceContent(textAreaEl, templateBody, onInit);

    if (enableAi) {
      setParentIdOnForm(event.target, textAreaEl);
    }

    return componentEl;
  }

  const template = document.getElementById("edit-message-template");
  const programId = messageEl.dataset.templateProgramId;
  const taskId = messageEl.dataset.templateTaskId;
  const mentionsEnabled = messageEl.dataset.templateMentionsEnabled;
  const taskGroupId = messageEl.dataset.templateTaskGroupId;
  const mentionsNoMatchTranslation = template.dataset.mentionsNoMatchTranslation;

  componentEl = template.content.firstElementChild.cloneNode(true);

  // TrixEditor element from the newly created node
  const textAreaEl = componentEl.querySelector('[data-template-target="textarea"]');

  componentEl.id = componentId;
  componentEl.action = `/programs/${programId}/messages/${messageId}`;
  componentEl.dataset.editInPlaceTarget = "form";
  componentEl.dataset.testEditForm = "";

  if (mentionsEnabled == "true") {
    textAreaEl.dataset.controller = "rich-text-mentions";
    textAreaEl.dataset.richTextMentionsTarget = "field";
    textAreaEl.dataset.richTextMentionsNoMatchValue = mentionsNoMatchTranslation;
    componentEl.dataset.controller = "form-reset";

    if (taskGroupId == undefined) {
      textAreaEl.dataset.richTextMentionsUrlValue = `/programs/${programId}/mentions.json`;
    }
    else {
      textAreaEl.dataset.richTextMentionsUrlValue = `/programs/${programId}/mentions.json?task_id=${taskId}&task_group_id=${taskGroupId}`;
    }
  } else {
    componentEl.dataset.controller = "form-reset messages-disable-button";
  }

  if (enableAi) {
    setParentIdOnForm(event.target, textAreaEl);
    textAreaEl.dataset.controller = `${textAreaEl.dataset.controller} rich-text-ai`;
    textAreaEl.dataset.richTextAiTarget = "field";
    textAreaEl.dataset.richTextAiMenuId = "rich-text-ai-menu";
    textAreaEl.dataset.richTextAiPath = "/rich_text_ai";
  }

  const targetNode = messageEl.querySelector(`[data-template-target="message-${messageId}"]`);

  let templateIsForChat = targetNode.dataset.templateForChat;

  if (templateIsForChat) {
    document.getElementById(`js-message-${messageId}`).insertAdjacentElement("beforeend", componentEl);
  } else {
    targetNode.insertAdjacentElement("afterend", componentEl);
  }

  //Set custom ids for trix input and textarea copied from template
  let inputEl = componentEl.querySelector(`#${textAreaEl.inputElement.id}`);
  inputEl.id = `trix_input_${messageId}_edit_form`;
  textAreaEl.id = `trix_textarea_${messageId}_edit_form`;
  textAreaEl.setAttribute("input", `trix_input_${messageId}_edit_form`);

  // Copy original message content into the new TrixEditor element. Must be done
  // after the element is in the DOM otherwise .editor will not have been
  // initialized yet
  addEventListener("trix-initialize", onInit);

  function onInit(event) {
    replaceContent(textAreaEl, templateBody, onInit);
  }

  return componentEl;
}

function replaceContent(textAreaEl, templateBody, onInit){
  textAreaEl.editor.setSelectedRange([0, 0]);
  textAreaEl.editor.loadHTML(templateBody);
  removeEventListener("trix-initialize", onInit);

  PromoteHelpers.dispatchEvent({
    target: document,
    type: 'promote:focus-textarea',
    detail: {
      'elm': textAreaEl
    }
  });
}

function setParentIdOnForm(target, textAreaEl) {
  let messageFormId;
  let messageSourceForm = target.closest("[id^=js-message-child-replies-]");

  if (messageSourceForm) {
    messageFormId = messageSourceForm.id;
  } else if (target.closest("[id^=js-message-replies-]")) {
    messageFormId = target.closest("[id^=js-message-replies-]").id;
  }

  if (messageFormId) {
    textAreaEl.dataset.aiParentId = messageFormId.split('-').slice(-1)[0];
  }
}

function enableAiForMessage(aiEnabled, messageEl) {
  if (aiEnabled === 'false' || messageEl.dataset.templateSecondReply === 'true') {
    return false;
  }

  return messageEl.id.match('js-reply-');
}
