import { ajax } from '@rails/ujs';
import { Controller } from 'stimulus';

import PromoteHelpers from '../ng_admin/deprecated_promote_helpers.js';

export default class extends Controller {
  connect() {
    this.inputsChanged = new Set();
    this.activeClass = this.data.get("activeId");
    this.activeSelector = "." + this.activeClass;
    this.activeList = document.getElementById(this.data.get("listId"));
    this.listItemId = this.data.get("listItemId");
    this.scrollTop = this.data.get("scrollTop");
    this.scrollToId = this.data.get("scrollToId");
    this.scrollAutomatically = this.data.get("scrollAutomatically")
    document.addEventListener("promote:input-changed", this.onChange.bind(this));
    document.addEventListener("promote:cancel-changes", this.onCancelChanges.bind(this));

    if (this.scrollAutomatically) {
      this._scrollToActiveItem();
    }
  }

  onChange(event) {
    if ( event.detail?.id )
    {
      this.inputsChanged.add(event.detail.id);
    }
  }

  onCancelChanges(event) {
    this.inputsChanged.delete(event.detail?.id);
  }

  onConfirmBox(event) {
    if ( this.inputsChanged.size == 0) {
      return;
    }

    document.removeEventListener("promote:confirm-box", this.onConfirmBox.bind(this));

    // confirm once to leave the page
    this.inputsChanged.clear();
    this.load(event.detail.originalEvent);
  }

  visit(event) {
    event.preventDefault();

    if ( this.inputsChanged.size > 0 )
    {
      document.addEventListener("promote:confirm-box", this.onConfirmBox.bind(this));

      PromoteHelpers.dispatchEvent({
        target: document,
        type: 'turbolinks:before-visit',
        detail: {
          originalEvent: event,
        }
      });
    }
    else
    {
      this.load(event);
    }
  }

  load(event) {
    if (this.activeList) {
      const oldActiveItem = this.activeList.querySelector(this.activeSelector);
      const newActiveItem = (this.listItemId) ? this.activeList.querySelector("#" + this.listItemId) : event.currentTarget

      this._updateActiveItem(oldActiveItem, newActiveItem);
    }

    const url = event.currentTarget.pathname + event.currentTarget.search;
    this._load(url);
  }

  _scrollToActiveItem() {
    const activeItem = this.activeList.querySelector(this.activeSelector);

    if(activeItem) {
      activeItem.scrollIntoView();
    }
  }

  _updateActiveItem(oldActiveItem, newActiveItem) {
    // remove active id and class from old active list item, if any
    if (oldActiveItem) {
      oldActiveItem.classList.remove(this.activeClass);
    }

    // add active id to newly selected list item
    newActiveItem.classList.add(this.activeClass);
  }

  _load(url) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: url,
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: (response, statusText, xhr) => {
        window.history.pushState({}, "", url);

        if (this.scrollTop) {
          document.getElementsByTagName("html")[0].scrollTop = 0;
        } else if (this.scrollToId) {
          window.scroll(0, document.getElementById(this.scrollToId).offsetTop);
        }
      },
      error: (response, statusText, xhr) => {
        // Redirect the window to the error page
        window.location = xhr.responseURL;
      },
    });
  }
}
